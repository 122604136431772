<template>
<div>
  <b-row class="profile-icons-svg">
    <b-col lg="4">
      <div class="iq-card ">
        <div class="iq-card-body pb-1">
          <div class="d-flex align-items-center justify-content-between">
            <p class="font-size-26 p-0 m-0">{{ $t('userProfile.insurance') }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="32.483" height="32.483" viewBox="0 0 37.483 37.483">
              <path id="_17e264738a73720c3ce88a761514fcfb" data-name="17e264738a73720c3ce88a761514fcfb" d="M16.78,1.781a3.282,3.282,0,0,1,5.079,0l1.627,1.988,2.4-.908a3.282,3.282,0,0,1,4.4,2.539L30.7,7.936l2.535.416a3.282,3.282,0,0,1,2.539,4.4l-.908,2.4,1.988,1.628a3.282,3.282,0,0,1,0,5.079L34.87,23.487l.908,2.4a3.282,3.282,0,0,1-2.539,4.4L30.7,30.7l-.415,2.535a3.282,3.282,0,0,1-4.4,2.539l-2.4-.908-1.627,1.988a3.282,3.282,0,0,1-5.079,0l-1.628-1.988-2.4.908a3.282,3.282,0,0,1-4.4-2.539L7.936,30.7,5.4,30.288a3.282,3.282,0,0,1-2.539-4.4l.908-2.4L1.781,21.859a3.282,3.282,0,0,1,0-5.079l1.988-1.628-.908-2.4A3.282,3.282,0,0,1,5.4,8.352l2.535-.416L8.351,5.4a3.282,3.282,0,0,1,4.4-2.539l2.4.908Zm1.239,24.442L29.505,14.737l-2.321-2.321L16.859,22.742l-5.4-5.4L9.135,19.66,15.7,26.223a1.641,1.641,0,0,0,2.321,0Z" transform="translate(-0.578 -0.579)" fill="#646464" fill-rule="evenodd"/>
            </svg>
          </div>
          <p class="font-size-30 text-end m-0 pt-4">{{ pagination.itemCount }}</p>
        </div>
      </div>
    </b-col>
  </b-row>
  <user-pages-title>
  <template v-slot:header-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="32.483" height="32.483" viewBox="0 0 37.483 37.483">
      <path id="_17e264738a73720c3ce88a761514fcfb" data-name="17e264738a73720c3ce88a761514fcfb" d="M16.78,1.781a3.282,3.282,0,0,1,5.079,0l1.627,1.988,2.4-.908a3.282,3.282,0,0,1,4.4,2.539L30.7,7.936l2.535.416a3.282,3.282,0,0,1,2.539,4.4l-.908,2.4,1.988,1.628a3.282,3.282,0,0,1,0,5.079L34.87,23.487l.908,2.4a3.282,3.282,0,0,1-2.539,4.4L30.7,30.7l-.415,2.535a3.282,3.282,0,0,1-4.4,2.539l-2.4-.908-1.627,1.988a3.282,3.282,0,0,1-5.079,0l-1.628-1.988-2.4.908a3.282,3.282,0,0,1-4.4-2.539L7.936,30.7,5.4,30.288a3.282,3.282,0,0,1-2.539-4.4l.908-2.4L1.781,21.859a3.282,3.282,0,0,1,0-5.079l1.988-1.628-.908-2.4A3.282,3.282,0,0,1,5.4,8.352l2.535-.416L8.351,5.4a3.282,3.282,0,0,1,4.4-2.539l2.4.908Zm1.239,24.442L29.505,14.737l-2.321-2.321L16.859,22.742l-5.4-5.4L9.135,19.66,15.7,26.223a1.641,1.641,0,0,0,2.321,0Z" transform="translate(-0.578 -0.579)" fill="#646464" fill-rule="evenodd"/>
    </svg>
  </template>
  <template v-slot:header-title>
    <p class="text-muted font-size-22 m-0 p-0">{{$t('userProfile.insuranceDetails')}}</p>
  </template>
  <template v-slot:btn-name>
    <router-link :to="{name: 'registerInsurance'}" class="d-flex align-items-center">
      <i class="las la-plus-square text-white font-size-22"></i>
      <span class="font-size-16 text-white">{{$t('userProfile.insuranceNow')}}</span>
    </router-link>
  </template>
</user-pages-title>
  <div v-if="loadingData" class="d-flex justify-content-center">
    <spinner-loading text="Loading"/>
  </div>
  <div v-else>
      <form class="insuraceForm mb-4">
        <b-row>
          <b-col lg="4">
            <div class="d-flex align-items-center">
              <label class="m-0"><span class="ml-2 font-size-18 text-muted">{{ $t('userProfile.company') }}</span></label>
              <main-select
                  @change="getUserOrder()"
                  :reduce="company => company.id"
                  :options=insuranceCompanies
                  v-model="company"
                  label="name"
                  class="flex-grow-1 mb-0 w-100"
                  :placeholder="$t('userProfile.company')"
                  dir="rtl"
              />
            </div>
          </b-col>
          <b-col lg="4">
            <div class="d-flex align-items-center">
              <label class="m-0"><span class="ml-2 font-size-18 text-muted">{{ $t('userProfile.status') }}</span></label>
              <main-select
                  @change="getUserOrder()"
                  :options="status"
                  label="name"
                  :reduce="item => item.value"
                  class="flex-grow-1 mb-0 w-100"
                  :placeholder="$t('userProfile.status')"
                  dir="rtl"
                  v-model="filter.status"
              />
            </div>
          </b-col>
          <b-col lg="4">
            <div class="d-flex align-items-center">
              <label class="m-0"><span class="ml-2 font-size-18 text-muted">{{ $t('main.search') }}</span></label>
              <div
                  class="inputContainer flex-grow-1 bg-white ps-3 d-flex align-items-center px-3 rounded flex-grow-1 "
              >
                <i class="las la-search text-gray font-size-22"></i>
                <b-input
                    @change="updateSearchId(id)"
                    :placeholder="$t('userProfile.searchHere')"
                    class="bg-transparent border-0 flex-grow-1 text-muted"
                    v-model="id"
                ></b-input>
              </div>
            </div>
          </b-col>
        </b-row>
      </form>
      <div v-if="allInsurance.length">
        <iq-card
            class="py-3 px-3 position-relative"
            v-for="(insurance, key) in allInsurance" :key="key"
            v-b-toggle="`more-content-${insurance.id}`"
            @click.native="toogleCards(insurance.id)"
        >
          <template>
            <b-row v-if="insurance.id !== selectedId" class="align-items-center pl-5">
              <b-col lg="2">
                <div class="prod-img">
                  <img :src='insurance.service_provider.image'>
                </div>
              </b-col>
              <b-col lg="3" >
                <div class="d-flex flex-column">
                  <p class="m-0 p-0 font-size-16 text-secondary">{{ $t('insurance.policyNumber') }}</p>
                  <p class="m-0 p-0 font-size-18 text-muted">#{{insurance.id >= 7000 ? insurance.id : 7000 + Number(insurance.id)}}</p>
                </div>
              </b-col>
              <b-col lg="3">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center gap_1">
                    <span class="font-size-16 text-secondary">{{ $t('insurance.from') }}</span>
                    <p class="m-0 p-0 font-size-18 text-muted">{{insurance.date}}</p>
                  </div>
                  <div class="d-flex align-items-center gap_1">
                    <span class="font-size-16 text-secondary">{{ $t('insurance.to') }}</span>
                    <p class="m-0 p-0 font-size-18 text-muted">{{ insurance.date }}</p>
                  </div>
                </div>
              </b-col>
              <b-col lg="3">
                <div class="d-flex flex-column">
                  <p class="m-0 p-0 font-size-16 text-secondary">{{ $t('insurance.camelTotalValue') }}</p>
                  <p class="m-0 p-0 font-size-20 text-warning" v-if="insurance.service_provider">
                    {{( (Number(insurance.service_provider.options.tax)  / 100) * insurance.transportation_coverage) + Number(insurance.transportation_coverage) + Number(insurance.service_provider.options.certificate_fees) }}
                    <span>{{ $t('insurance.rs') }}</span>
                  </p>
                </div>
              </b-col>
              <b-col lg="1">
                <div class="arrow-collapse-open">
                  <i class="las la-angle-down text-secondary"></i>
                </div>
              </b-col>
              <div class="budge-label" v-if="!ifBeforeNow(insurance.date)">
                <p>سارية</p>
              </div>
              <div class="budge-label-danger" v-else>
                <p>غير سارية</p>
              </div>
            </b-row>
            <b-row v-else class="align-items-center mb-5">
              <b-col lg="2">
                <div class="prod-img">
                  <img :src="insurance.service_provider.image">
                </div>
              </b-col>
              <b-col lg="4">
                <div class="d-flex flex-column mr-3">
                  <p class="m-0 p-0 font-size-16 text-secondary">{{ $t('insurance.insuranceCompany') }}</p>
                  <p class="m-0 p-0 font-size-18 text-muted">{{ insurance.service_provider.name }}</p>
                </div>
              </b-col>
              <b-col lg="4" >
                <div class="d-flex flex-column">
                  <p class="m-0 p-0 font-size-16 text-secondary">{{ $t('insurance.policyNumber') }}</p>
                  <p class="m-0 p-0 font-size-18 text-muted">#{{insurance.id > 7000 ? insurance.id : 7000 + Number(insurance.id)}}</p>
                </div>
              </b-col>
              <b-col lg="1">
                <div class="budge-label-inside" v-if="!ifBeforeNow(insurance.date)">
                  <p class="p-0 m-0">سارية</p>
                </div>
                <div class="budge-label-inside-danger" v-else>
                  <p class="p-0 m-0">غير سارية</p>
                </div>
              </b-col>
            </b-row>

            <b-collapse :id="`more-content-${insurance.id}`" class="p-0" accordion="my-accordion">
              <div>
                <b-row v-if="insurance.id === selectedId">
                  <b-col lg="12">
                    <div class="fees-company-item">
                      <div class="d-flex justify-content-between align-items-start flex-md-row flex-column">
                        <div class="d-flex gap_3">
                          <div>
                            <div class="mb-4">
                              <h5 class="fees-company-item-title text-secondary">   {{ $t('insurance.insurancePeriod') }}</h5>
                              <div class="d-flex flex-column mt-3">
                                <div class="d-flex align-items-start gap_1 mb-4">
                                  <span class="font-size-16 text-secondary">{{ $t('insurance.from') }}</span>
                                  <p class="m-0 p-0 font-size-18 d-flex flex-column text-muted">
                                    <span>{{insurance.date}}</span>
                                    <span>12:00 AM</span>
                                  </p>
                                </div>
                                <div class="d-flex align-items-start gap_1">
                                  <span class="font-size-16 text-secondary">{{ $t('insurance.to') }}</span>
                                  <p class="m-0 p-0 font-size-18 d-flex flex-column text-muted">
                                    <span>{{insurance.date}}</span>
                                    <span>08:00 PM</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="mb-4">
                              <h5 class="text-secondary">{{ $t('insurance.bookingDate') }}</h5>
                              <h5 class=" font-size-16">{{insurance.created_at.substring(0,10)}} / {{insurance.created_at.substring(11, 19)}}</h5>
                            </div>
                            <div class="mb-4">
                              <h5 class="text-secondary"> {{ $t('insurance.coverage') }}</h5>
                              <h5 class=" font-size-16">All risk-Institute Cargo Clause A</h5>
                            </div>
                            <div class="mb-4">
                              <h5 class="text-muted">War & SRCCC (0.005% on SI)</h5>
                              <h5 class="text-muted font-size-16">Included</h5>
                            </div>
                            <div class="mb-4">
                              <h5 class="text-secondary">{{ $t('insurance.discountQuantity') }}</h5>
                              <h5 class="text-muted font-size-16">0.10% of the Shipment Value. Subject to minimum</h5>
                            </div>
                            <div class="mb-4">
                              <h5 class="text-secondary">{{ $t('insurance.amountInsured') }}</h5>
                              <h5 class="text-muted font-size-25">5,000 ريال سعودي</h5>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div>
                            <div class="mb-4">
                              <h5 class="fees-company-item-title text-secondary">{{ $t('insurance.idHolder') }}</h5>
                              <h5 class="fees-company-item-response font-size-16 text-muted">{{ insurance.user_id }}</h5>
                            </div>
                            <div class="mb-4">
                              <h5 class="fees-company-item-title text-secondary">{{ $t('insurance.transportationCoverage') }}</h5>
                              <h5 class="fees-company-item-response font-size-16 text-muted">300 ريال سعودي</h5>
                            </div>
                            <div class="mb-4">
                              <h5 class="fees-company-item-title">   {{ $t('insurance.valueTax') }} {{ insurance.service_provider.options.tax }}%</h5>
                              <h5 class="fees-company-item-response">{{ (insurance.service_provider.options.tax  / 100) * insurance.transportation_coverage }} {{ $t('insurance.rs') }}</h5>
                            </div>
                            <div class="mb-4">
                              <h5 class="fees-company-item-title text-secondary">{{ $t('insurance.CertificationFees') }}</h5>
                              <h5 class="fees-company-item-response">{{insurance.service_provider.options.certificate_fees}}
                                {{ $t('insurance.rs') }}</h5>
                            </div>
                            <hr/>
                            <div class="mb-4">
                              <h5 class="fees-company-item-response text-secondary font-size-16">{{ $t('insurance.camelTotalValue') }}</h5>
                              <h3 class="font-weight-bold text-warning">
                                {{((insurance.service_provider.options.tax  / 100) * insurance.transportation_coverage) + insurance.transportation_coverage + insurance.service_provider.options.certificate_fees }}
                                <span>{{ $t('insurance.rs') }}</span>
                              </h3>
                            </div>
                            <div class="d-flex justify-content-start gap_2">
                              <b-button variant="primary" class="px-4 py-2 iq-border-radius-5 text-center" @click.prevent.self="downloadPolicy(insurance)">
                                {{ $t('insurance.insurancePolicy') }}
                              </b-button>
                              <b-button variant="outline-warning" class="px-4 py-2 iq-border-radius-5 text-center" @click.prevent.self="downloadWasl(insurance)">
                                {{ $t('insurance.policyNumber') }}
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
            <span class="close-collapse" v-if="insurance.id === selectedId">
          <i class="las la-angle-up"></i>
        </span>
          </template>
        </iq-card>
        <div class="d-flex justify-content-center">
          <b-pagination
              v-if="pagination.itemCount > pagination.itemsPerPage"
              :disabled="loadingData"
              v-model="pagination.currentPage"
              :total-rows="pagination.itemCount"
              :per-page="pagination.itemsPerPage"
              first-number
              last-number
              class="mb-3 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              align="right"
              @input="getUserOrder(pagination.currentPage)"
          >
          </b-pagination>
        </div>
      </div>
      <p v-else> No Available Data</p>
  </div>

</div>
</template>

<script>
import userPagesTitle from '@/modules/userProfile/components/userPagesTitle'
import insuranceProfileServices from '../services/userProfile'
import moment from 'moment'
import citiesAndCountriesMixin from '@/mixins/countriesAndCities'
export default {
  name: 'insurance-profile',
  mixins: [citiesAndCountriesMixin],
  components: { userPagesTitle },
  watch: {
    'filter.status' (val) {
      if (val) { this.getUserOrder(1) }
    }
  },
  data () {
    return {
      company: '',
      visible: false,
      insuranceCompanies: [],
      id: '',
      status: [
        {
          name: 'الكل',
          value: ''
        },
        {
          name: 'سارية',
          value: 'active'
        },
        {
          name: 'غير سارية',
          value: 'disactive'
        }
      ],
      allInsurance: [],
      loadingData: false,
      selectedId: '',
      filter: {
        status: ''
      },
      pagination: {
        totalItems: '',
        itemsPerPage: '',
        currentPage: ''
      }
    }
  },
  methods: {
    getCompanies () {
      insuranceProfileServices.getInsuranceCompany().then(res => {
        this.insuranceCompanies = [{ name: 'الكل', id: '' }, ...res.data.data]
      })
    },
    updateSearchId (cat) {
      this.allInsurance = []
      this.pagination.currentPage = 1
      this.id = cat
      this.getUserOrder()
    },
    downloadPolicy (insurance) {
      if (insurance.policies) {
        window.open(insurance.policies, '_blank')
      } else {
        this.loadingData = true
        insuranceProfileServices.getInsuranceInvoice(insurance.id).then(res => {
          insurance.invoice = res.data.invoice
          insurance.policies = res.data.policies
          this.loadingData = false
          window.open(res.data.policies, '_blank')
        })
      }
    },
    downloadWasl (insurance) {
      if (insurance.invoice) {
        window.open(insurance.invoice, '_blank')
      } else {
        this.loadingData = true
        insuranceProfileServices.getInsuranceInvoice(insurance.id).then(res => {
          insurance.invoice = res.data.invoice
          insurance.policies = res.data.policies
          this.loadingData = false
          window.open(res.data.invoice, '_blank')
        })
      }
    },
    getUserOrder (page = 1) {
      this.loadingData = true
      insuranceProfileServices.getUserOrder(this.id, this.company, this.filter.status, page).then(res => {
        this.allInsurance = res.data.data
        this.pagination = res.data.meta
        this.loadingData = false
      })
    },
    toogleCards (id) {
      if (this.selectedId === id) {
        this.selectedId = ''
      } else {
        this.selectedId = id
      }
    },
    ifBeforeNow (data) {
      const dateAfter24Hour = moment(new Date(data)).add(1, 'days').format()
      console.log('dateAfter24Hour', dateAfter24Hour)
      const diff = moment(new Date()).diff(dateAfter24Hour)
      console.log(diff)
      if (diff > 0) {
        return true
      } else {
        return false
      }
    }
  },
  computed: {
  },
  created () {
    this.getCompanies()
    this.getUserOrder()
  }
}
</script>

<style>
.font-size-25{
  font-size: 25px !important;
}
.prod-img img{
  background-size: cover;
  width: 100%;
  height: 100%;
}
.budge-label {
  max-width: 32px;
  position: absolute;
  background-color: #5dec9b;
  color: #fff;
  left: 0;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px
}
.budge-label-danger {
  max-width: 32px;
  position: absolute;
  background-color: #be1919;
  color: #fff;
  left: 0;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px
}
.budge-label p , .budge-label-danger p{
  transform: rotate(90deg);
  padding: 0 !important;
  white-space: nowrap;
  transform-origin: center;
  margin: 0 !important;
}
.close-collapse {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -20px;
  background-color: var(--iq-primary);
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50% - 20px);
}
.arrow-collapse-open{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.53);
  border-radius: 5px;
}
.budge-label-inside{
  background-color: #5dec9b;
  color: #fff;
  text-align: center;
  display: inline-block;
  padding: 0 20px;
}.budge-label-inside-danger{
  background-color: #be1919;
  color: #fff;
  text-align: center;
  display: inline-block;
  padding: 0 20px;
}
</style>
